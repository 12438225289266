import { Box, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { FC } from 'react';
import useHighlightedText from '#/hooks/useHighlightedText';

export const NoteriseByLawyer: FC<{
  context: string;
  targets: string[];
  onClick?: (word?: string) => void;
}> = ({ context, targets, onClick }) => {
  const highlightFormat = (word: string, key: number) => (
    <Box
      component="span"
      key={key}
      sx={{ color: 'primary.main', cursor: 'pointer' }}
      onClick={() => onClick && onClick(word)}
    >
      {word}
    </Box>
  );
  const highlightedText = useHighlightedText({ targets, highlightFormat });

  return <>{highlightedText(context)}</>;
};

export const LinkToSettings: FC<{
  context: string;
  targets: string[];
}> = ({ context, targets }) => {
  const highlightFormat = (word: string, key: number) => (
    <Link
      component={RouterLink}
      to="/dashboard/user/account"
      state={{ activeTab: 'successors' }}
      key={key}
      sx={{ color: 'primary.main', cursor: 'pointer', fontSize: '1rem' }}
    >
      <span>{word}</span>
    </Link>
  );
  const highlightedText = useHighlightedText({ targets, highlightFormat });

  return <>{highlightedText(context)}</>;
};
